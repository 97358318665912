import style from "./CourseProductComponent.module.css";
import Title from "../../../../component/title/TitleComponent";

import japan1 from "assets/course_product/japan/1.png";
import japan2 from "assets/course_product/japan/2.png";
import japan3 from "assets/course_product/japan/3.png";
import japan4 from "assets/course_product/japan/4.png";
import japan5 from "assets/course_product/japan/5.png";

import korean1 from "assets/course_product/korean/1.png";
import korean2 from "assets/course_product/korean/2.png";
import korean3 from "assets/course_product/korean/3.png";
import korean4 from "assets/course_product/korean/4.png";

const CourseProduct = (props) => {
  const { clickMenu, type } = props;

  const width = window.innerWidth;

  return (
    <div>
      <Title
        className={style.title_box}
        mtX="1.67vw"
        title={type === "japan" ? "コースの紹介" : "4대 상품 종류"}
        subtitle={type === "japan" ? "课程产品介绍" : "4大课程产品"}
        aline="center"
      />

      <div className={style.img_box}>
        <div className={style.single_img}>
          <img
            className={style.course}
            src={type === "japan" ? japan1 : korean1}
            alt=""
          />
          <div className={style.btn} onClick={clickMenu}></div>
        </div>
        <div className={style.single_img}>
          <img
            className={style.course}
            src={type === "japan" ? japan2 : korean2}
            alt=""
          />
          <div className={style.btn} onClick={clickMenu}></div>
        </div>
        <div className={style.single_img}>
          <img
            className={style.course}
            src={type === "japan" ? japan3 : korean3}
            alt=""
          />
          <div className={style.btn} onClick={clickMenu}></div>
        </div>
        <div className={style.single_img}>
          <img
            className={style.course}
            src={type === "japan" ? japan4 : korean4}
            alt=""
          />
          <div className={style.btn} onClick={clickMenu}></div>
        </div>
        {type === "japan" && (
          <div className={style.single_img}>
            <img className={style.course} src={japan5} alt="" />
            <div className={style.btn} onClick={clickMenu}></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CourseProduct;
